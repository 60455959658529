"use client"

import { useEffect } from "react"

import { useTranslations } from "next-intl"

import {
  DMButtonNeutral,
  DMButtonPrimary,
  DMIllustratedMessage,
  DMWell,
} from "@supernovaio/dm"

import errorMediumSvg from "@supernovaio/assets/illustrations/error_medium.svg"
import errorMediumDarkSvg from "@supernovaio/assets/illustrations/error_medium_dark.svg"
import { useSupport } from "@supernovaio/cloud/features/support/useSupport"
import { IconRefresh, IconSupport } from "@supernovaio/icons"
import { SupernovaError } from "@supernovaio/sdk"

import { useErrorHandler } from "../../hooks/useErrorHandler"
import { Illustration } from "../components/Illustration"
import { PageLayoutTypeCentering } from "../page/layouts/PageLayoutTypeCentering"

export default function ErrorBoundaryView({
  error,
  reset,
}: {
  error: Error
  reset?: () => void
}) {
  const { handleBoundaryError } = useErrorHandler()
  const t = useTranslations("ErrorBoundaryView")

  useEffect(() => {
    handleBoundaryError(error)
  }, [error])

  const errorToReadable = (err: Error | SupernovaError): string => {
    if (err instanceof SupernovaError) {
      return err.toHumanReadable()
    }

    return err.message
  }

  const { openChat } = useSupport()

  return (
    <PageLayoutTypeCentering>
      <div className="flex flex-col gap-12">
        <DMIllustratedMessage
          title={t("title")}
          description={t("description")}
          illustration={<Illustration src={errorMediumSvg} srcDark={errorMediumDarkSvg} />}
          descriptionSlot={
            <DMWell variant="critical">Reason: {errorToReadable(error)}</DMWell>
          }
          actionsSlot={
            <div>
              <DMButtonPrimary icon={IconSupport} onClick={openChat}>
                {t("contactSupportCTA")}
              </DMButtonPrimary>
              <DMButtonNeutral
                icon={IconRefresh}
                onClick={
                  // Re-render segment that errored out
                  () => {
                    if (reset) {
                      reset()
                    }
                  }
                }
              >
                {t("refreshCTA")}
              </DMButtonNeutral>
            </div>
          }
        />
      </div>
    </PageLayoutTypeCentering>
  )
}
